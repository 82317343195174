import moment from 'moment';

export const formatDate = (d: string, format = 'LLL') =>
  moment(d).format(format);

export const numberFormat = (
  number: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
) => {
  if (!number) {
    return '0.00';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);
};

export const titleCase = (str: string) => {
  if (!str) return str;
  const words = str.toLowerCase().split(' ');
  return words
    .map(word => word[0].toUpperCase().concat(word.slice(1)))
    .join(' ');
};

export const unitTest = (testNumber: number) => testNumber + 1;

const CURRENCY_NETWORK_KEY = 'YC-CURRENCY-NETWORK';

export function setCurrencyNetwork(value: string) {
  localStorage.setItem(CURRENCY_NETWORK_KEY, value);
}

export function getCurrencyNetwork() {
  return localStorage.getItem(CURRENCY_NETWORK_KEY) || 'usdt-trc20';
}

export const currencyNetworkMap: Record<
  any,
  { name: string; network: string; icon: string; label: string }
> = {
  'usdt-erc20': {
    name: 'Tether (USDT) ERC-20',
    network: 'Ethereum (ERC20)',
    icon: 'USDT',
    label: 'USDT (ERC-20)',
  },
  'usdt-trc20': {
    name: 'Tether (USDT) TRC-20',
    network: 'TRON (TRC-20)',
    icon: 'USDT',
    label: 'USDT (TRC-20)',
  },
  'usdc-erc20': {
    name: 'USDC (ERC-20)',
    network: 'Ethereum (ERC20)',
    icon: 'USDC',
    label: 'USDC (ERC-20)',
  },
  'usdc-eth': {
    name: 'USDC (ETH)',
    network: 'Ethereum (ERC20)',
    icon: 'USDC',
    label: 'USDC (ETH)',
  },
  'usdc-pol': {
    name: 'USDC (POL)',
    network: 'Polygon (POL)',
    icon: 'USDC',
    label: 'USDC (POL)',
  },
  'btc-btc': {
    name: 'Bitcoin (BTC)',
    network: 'Bitcoin (BTC)',
    icon: 'BTC',
    label: 'BTC (BTC)',
  },
  'usdc-xlm': {
    name: 'USDC (XLM)',
    network: 'Stellar (XLM)',
    icon: 'XLM',
    label: 'USDC (XLM)',
  },
};

export default {};
