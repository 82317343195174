export default {
  home: {
    headingText: 'Hello world!',
    bodyText: 'This is a POC for Vue 3 component library',
  },
  login: {
    headingText: 'Log in to your account',
    caption: "Don't have an account?",
    labels: {
      email: 'Email address',
    },
    passwordReset: 'How do I reset my password? ',
  },
  passwordReset: {
    headingText: 'Reset Password',
    caption: 'Choose a new password',
  },
  twoFactorAuth: {
    headingText: 'Setup 2-factor authentication',
    authCodePrompt: 'Please input your authentication code',
    headingText2: '2-factor authentication',
    caption2:
      'Enter the six digit OTP you received from your authentication app',
  },
  accountInactive: {
    headingText: 'Sorry, your account is inactive',
    caption:
      "You don't have access to this dashboard currently. Contact your administrator if this is an error.",
  },
  passwordChanged: {
    headingText: 'You changed your password',
    caption: 'You have successfully changed your password',
  },
  downloadCSV: {
    headingText: 'Are you sure you want to download this document?',
    bodyText: 'This document will be downloaded and saved in your system files',
  },
  buttons: {
    next: 'Next',
    cancel: 'Cancel',
    download: 'Download',
    delete: 'Delete',
    reset: 'Reset',
    apply: 'Apply',
    confirm: 'Confirm',
  },
  feesDetails: {
    transactionType: 'Transaction Type',
    date: 'Date',
    transactionId: 'Transaction ID',
    paymentMethod: 'Payment Method',
    viewTransaction: 'View Transaction',
  },
  walletManagement: {
    headingText: 'Wallet management',
    noWalletAddresses: 'No wallet addresses.',
    walletsLimitNotice:
      'You have a limit of 3 enabled addresses per asset-network pairing.',
    noWalletAddressesMessage:
      'Click on “Add new” to add up to 3 wallet addresses per asset for payouts.',
  },
  createWalletModal: {
    headingText: 'Add a new wallet address',
    subHeadingText: 'This will be used for payouts.',
    alert:
      'Ensure your wallet address is accurate. Blockchain transactions are irreversible. Funds sent to inaccurate addresses will be lost.',
    otpHeader: 'Enter your OTP',
    otpMessage:
      'Enter the six digit OTP you received in your email to confirm these changes to your wallet management.',
    otpPrompt: 'Please input your authentication code',
    invalidWalletAddress: 'Enter a valid wallet address',
    otpError: 'Invalid OTP',
  },
  reviewWalletModal: {
    headingText: 'Review address',
    subHeadingText: 'This address will be screened after your approval.',
    denyWalletHeader: 'Why are you denying this wallet?',
  },
  deleteWalletModal: {
    headingText: 'Delete wallet address',
    message:
      'Deleting this wallet address means you will not be able to make payouts to it in future. Are you sure you wish to proceed?',
    otpHeader: 'Enter your OTP',
    deleteReasonTitle: 'Why are you deleting this wallet address?',
    otpMessage: 'Enter the six digit OTP you received in your email',
  },
  disableWalletModal: {
    headingText: 'Disable this wallet address',
    message:
      "Disabling this address means you won't see it as a payout option. Are you sure you want to proceed?",
  },
  enableWalletModal: {
    headingText: 'Enable this wallet address',
    message:
      'Enabling this wallet means it will be available as an option for payout. Are you sure you want to proceed?',
  },
  transactionDetails: {
    transactionType: 'Transaction Type',
    status: 'Status',
    senderName: 'Sender Name',
    paymentMethod: 'Payment Method',
    sessionId: 'Session ID',
    sequenceId: 'Sequence ID',
    date: 'Date',
    conversionRate: 'Conversion Rate',
    transactionId: 'Transaction ID',
    tapToCopy: 'Tap to copy',
    externalWalletAddress: 'External Wallet Address',
    internalWalletAddress: 'Internal Wallet Address',
    transactionHash: 'Transaction Hash',
    serviceFee: 'Service Fee',
    networkFee: 'Network Fee',
    network: 'Network',
    settlementStatus: 'Settlement Status',
    receiveAddress: 'Receive Address',
    sendAddress: 'YC Send Address',
    currency: 'Currency',
  },
  settings: {
    headingText: 'Settings',
  },
  viewRoles: {
    view: 'View',
    admin: 'Admin',
    treasury: 'Treasury',
    teamMembers: 'Team members',
    permissions: 'Permissions',
    adminNotice: 'Admin can access all dashboard sections.',
  },
  balance: {
    headingText: 'Balance',
    availableBalance: 'Available Balance',
    topUpBalance: 'Top up balance',
    topUpBalanceHelper:
      'Please ensure you are sending USDT using the TRC20 network to top up your balance (sending through any other network will result in permanent loss)',
    topupButton: 'Top-up',
    emptyData:
      "When your customers start making transactions, they'll appear here",
    searchID: 'Search ID',
    lowBalancePill: 'Low Balance',
    dialog: {
      headingText: 'Top up your balance',
      walletAddress: 'Wallet address',
      network: 'Network',
      tag: 'USDC Tag',
    },
  },
  settlement: {
    text: 'Withdraw balance',
    dialog: {
      headingText: 'How much do you want to settle?',
      subText:
        'It takes about 5 - 20 minutes to process your funds to this wallet address',
      inputLabel: 'Enter amount in USD',
      inputSubtext: 'Max. settlement = USD ',
      errorSubtext: 'Enter amount below USD ',
      walletAddress: 'Wallet address',
      network: 'Network',
      buttonText: 'Next',
      warningText: 'Cannot withdraw funds without setting a wallet address',
      addressesTitle: 'Select your balance receive address and currency',
    },
    confirmDialog: {
      headingText: 'Confirm your settlement',
      subText: 'Check your settlement amount and wallet address',
      inputLabel: 'You receive',
      walletAddress: 'Wallet address',
      network: 'Network',
      currency: 'Currency',
      buttonText: 'Confirm',
      paymentAmount: 'Payment amount',
      networkFee: 'Network fee',
      receive: 'You receive',
    },
    acknowledgeDialog: {
      headingText: 'Your settlement is pending',
      subText:
        "We'll add this settlement to your balance history after processing",
      subText1: 'It’ll take just a moment to process your settlement.',
      subText2: 'You can check the status on your balance history.',
      buttonText: 'I understand',
    },
  },
  apiKey: {
    headingText: 'API Keys',
    buttonText: 'Create new',
    emptyStateText:
      'To begin, create a new API key and set all necessary permissions',
    publicKeyLabel: 'Public Key',
    secretKeyLabel: 'Secret Key',
    seeMore: 'See more',
    seeLess: 'See less',
    expiredKeyMessage:
      'Expired - This key is no longer active. Please create a new API key',
  },
  transactionsTable: {
    heading: 'Transactions History',
    text: {
      transactionNotFound1: 'The transaction ID ',
      transactionNotFound2:
        " doesn't match any ID in\n" +
        'the transaction history. Please enter a valid\n' +
        'transaction ID or download the CSV file for\n' +
        'reference.',
      transactionNotFoundForFilter:
        'No transactions found for selected filters.',
      transactionNotFoundForDateFilter:
        'You do not have any recorded transactions within this date range.',
    },
  },

  createKeysModal: {
    headingText: 'Create new API key',
    setKeysText: 'Set API permissions',
    success: {
      headingText: 'You created a new API key',
      caption:
        'You successfully created a new API key. Do not share ' +
        'this with anyone. Copy and store this key safely. ' +
        'If lost, you would have to create a new one.',
      inputPublicKey: 'Public Key',
      inputSecretKey: 'Secret Key',
    },
  },
  deleteKeyModal: {
    headingText: 'You are about to delete an API key',
    caption:
      'This will deny you access to any resource when you use this API key.',
  },

  inactiveModal: {
    headingText: 'Session about to expire',
    caption: 'You have been inactive for 5 minutes. Logging out in:',
  },
  inviteTeamMembersModal: {
    headingText: 'Invite team member',
    caption: 'Team member will be added to this dashboard when invited',
  },
  inviteTeamMembersConfirmation: {
    headingText: 'Are you sure?',
  },
  teamMembersTable: {
    text: {
      userNotFound1: 'The user ID ',
      userNotFound2:
        " doesn't match any ID in\n" +
        'the team members table. Please enter a valid\n' +
        'user ID',
      userNotFoundForFilter: 'No team members found for selected filters.',
    },
  },
  lowBalance: {
    alert:
      'Your balance is low. Please top-up soon to ensure your customers can transact smoothly',
    message:
      'Set your balance limit so we can notify you when you need to top up your account. ' +
      'This will help to ensure your customers always experience uninterrupted transactions.',
  },
  reviewWallet: {
    alert:
      'A new wallet address needs approval to proceed. Review this to approve or deny it.',
  },
  balancePayoutText: {
    balancePayoutTitle: 'Balance Payout',
    manualPayoutLabel: 'Manual Payout',
    automatedPayoutLabel: 'Automated Payout',
    manualPayout:
      'We will pay out the available balance only when you request it',
    automatedPayout:
      'We will automate your recurring payouts, over and above your threshold limit',
    nonAdminMessage: {
      manual:
        'Balance Payout has been set to Manual. You will need to request payout to receive funds.',
      automated: 'Balance Payout has been set to Automated.',
    },
    repeatsEveryLabel: 'Repeats Every',
    repeatsOnLabel: 'Repeats On',
    endsLabel: 'Ends',
    nonAdminAlert: 'You do not have edit access because you are not an admin',
  },
  deleteUserModal: {
    headingText: 'Delete team member',
    caption: 'Choosing delete will remove team member from the dashboard',
  },
  resetPasswordModal: {
    headingText: 'Are you sure?',
    caption:
      'Choosing reset will lock team member out of this dashboard until a new password is set',
  },
  changeRoleModal: {
    headingText: 'Edit team member role',
    caption: 'Choosing apply will change the role of the team member',
  },
  resendConfirmationCodeModal: {
    headingText: 'Resend confirmation code',
    caption:
      'Choosing confirm will resend a password confirmation code to the team member',
  },
};
